import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

const PageLayout = ({ children }: { children: ReactNode }) => {
  const [active, setActive] = useState(false);
  const handleMenuClick = () => {
    setActive(!active);
  };

  const date = new Date();

  const Logo = require("../assets/images/Logo.png");

  return (
    <>
      <nav className="shadow-b-md p-2 bg-white drop-shadow-lg">
        <div className="hidden container mx-auto px-2 md:flex justify-between flex-row items-center">
          <Link to="/">
            <img src={Logo} alt="logo" className="h-[50px]" />
          </Link>
          <div className="flex gap-[30px] text-[14px]">
            <a href="/#features">Features</a>
            <a href="/#pricing">Pricing</a>
            <a href="/#about">Company</a>
            <a href="/#contact">Contact us</a>
          </div>

          <Link
            to="/register"
            className="bg-green rounded-full px-[50px] py-[16px] text-white font-bold md:w-auto w-full shadow-md"
          >
            Get Started
          </Link>
        </div>
        <div className="md:hidden flex justify-between items-center">
          <img src={Logo} alt="logo" className="h-[40px]" />
          <MenuIcon onClick={handleMenuClick} />
        </div>
      </nav>
      {children}
      <div className="bg-[#F9F9F9]">
        <footer className="pt-[80px] pb-[41px] container mx-auto px-2 text-center md:text-[20px] text-[16px]">
          &copy; {JSON.stringify(date.getFullYear())} CoTrackr, All Rights
          Reserved.
          <br />
          <br />
          <div className="mt-3 text-[14px]">
            <Link to="/terms">Terms and Conditions</Link>
            {/* <br /> */}
            <br />
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </footer>
      </div>
    </>
  );
};

export default PageLayout;

const MenuIcon: React.FC<{ onClick: React.MouseEventHandler<SVGElement> }> = ({
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6 cursor-pointer"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
      />
    </svg>
  );
};
