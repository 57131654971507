import React from "react";

import AppLayout from "../AppLayout";
import { Tabs, TabsProps } from "antd";

import SecurityTab from "./Tabs/SecurityTab";
import SettingsTab from "./Tabs/SettingsTab";
import BillingsTab from "./Tabs/BillingsTab";
import Card from "../../../common/Card";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import PricingBanner from "./components/PricingBanner";

const Settings = () => {
  const { data: me } = useGetMeQuery();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General Settings",
      children: <SettingsTab me={me} />,
    },
    {
      key: "2",
      label: "Billings",
      children: <BillingsTab me={me} />,
    },
    {
      key: "3",
      label: "Security",
      children: <SecurityTab me={me} />,
    },
  ];
  return (
    <AppLayout title="Settings & Security">
      <PricingBanner />
      <Card>
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </AppLayout>
  );
};

export default Settings;
