import { Table } from "antd";
import AppLayout from "../AppLayout";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import NairaSign from "../../../common/NairaSign";
import { useState } from "react";
import { useGetEntriesQuery } from "../../../hooks/api/Entries/useGetEntriesQuery";
import { useDebounce } from "../../../hooks/useDebounce";

const Deposits = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const debouncedSearch = useDebounce(search);

  const { data: entries, isFetching: isFetchingEntries } = useGetEntriesQuery({
    search,
    tag: tag,
    isDeleted: false,
    startDate: "",
    endDate: "",
  });

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => (
        <span className="capitalize">{getTagLabel(tag)}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span>
          <NairaSign />
          {(amount / 100).toLocaleString()}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "bank",
      key: "details",
      render: (_: any, record: any) => (
        <div>
          <h3>{record.bank}</h3>
          <p>{record.accountNumber}</p>
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (_: any, record: any) => <div>{record.paymentMethod}</div>,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (_: any, record: any) => (
        <div>
          <Moment format="DD-MM-YYYY">{record?.transactionDate}</Moment>
        </div>
      ),
    },
    {
      title: "Date Recorded",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_: any, record: any) => (
        <div>
          <Moment format="DD-MM-YYYY">{record?.createdAt}</Moment>
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "entryReceipt",
    //   key: "entryReceipt",
    //   render: (_: any, record: any) => (
    //     <button
    //       className="text-green"
    //       onClick={(e) => {
    //         e.stopPropagation(); // Stop the row click event
    //         handleViewReceipt(record.entryReceipt);
    //       }}
    //     >
    //       View Receipt
    //     </button>
    //   ),
    // },
  ];

  return (
    <AppLayout title="Deposit & Savings">
      <div className="bg-white p-4 rounded-md">
        <div className="flex justify-between items-center">
          <span>
            <h3 className="text-black text-[16px]">All Deposits</h3>
            <p className="text-[14px] text-body-text">
              View Information on Member Deposit & Savings
            </p>
          </span>
          <Link
            to="/deposit-savings/add"
            className="border border-green text-green px-[35px] py-[10px] rounded-[100px] hover:bg-green hover:text-white transition-ease-in duration-300"
          >
            Add Entry
          </Link>
        </div>
        <div className="flex justify-end py-4 gap-4">
          <div className="flex items-center gap-1">
            {/* <Checkbox
              value={isDeleted}
              onChange={() => {
                setIsDeleted(!isDeleted);
              }}
            /> */}
            {/* <span>Is Deleted</span> */}
          </div>
          <input
            name="search"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            className={"p-[10px] rounded-md border-[1px] border-[#00000033]"}
          />
          {/* <RangePicker
            showTime
            onCalendarChange={(dates) => {
              if (dates && dates[0] && dates[1]) {
                const startDate = dates[0].toISOString();
                const endDate = dates[1].toISOString();
                setTiming({ startDate, endDate });
              }
            }}
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          /> */}
          <div className="flex gap-2 items-center">
            <span className="text-body-text">Payment Method:</span>
            <select
              className="p-3 rounded-md border-[1px] border-[#00000033]"
              name="tag"
              value={search}
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
            >
              <option value="" disabled>
                --Select Option--
              </option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Cash">Cash</option>
            </select>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-body-text">Tag:</span>
            <select
              className="p-3 rounded-md border-[1px] border-[#00000033]"
              name="tag"
              value={tag}
              onChange={(e) => {
                setTag(e?.target?.value);
              }}
            >
              <option value="" disabled>
                --Select Option--
              </option>
              <option value="savings">Savings</option>
              <option value="special_savings">Special Savings</option>
              <option value="monthly_dues">Monthly Dues</option>
              <option value="development">Development</option>
              <option value="other_savings">Other Savings</option>
              <option value="withdrawal">Withdrawal</option>
              <option value="shares">Shares</option>
              <option value="repayment">Repayment</option>
            </select>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          loading={isFetchingEntries}
          scroll={{ y: "55vh" }}
          dataSource={entries?.data?.docs}
          className="manrope mt-[10px] w-full"
          rowClassName="cursor-pointer"
          // loading={isGettingEntries}
          // pagination={paginationConfig}
          onRow={(record: any) => ({
            onClick: () =>
              navigate(`/deposit-savings/${record.id}/${record?.member}`),
          })}
        />
      </div>
    </AppLayout>
  );
};

export default Deposits;
