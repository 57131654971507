import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

export const useGetDashboardOverviewQuery = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const token = getToken();

  const params: {
    startDate?: string;
    endDate?: string;
  } = {};
  if (startDate) params.startDate = startDate;
  if (endDate) params.endDate = endDate;

  return useQuery({
    queryKey: ["useGetDashboardOverviewQuery", startDate, endDate],
    queryFn: () =>
      axiosInstance
        .get("/users/overview", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
