import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import AddMemberForm from "./Forms/AddMemberForm";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import { useEffect, useState } from "react";

const AddMember = () => {
  const { data } = useGetMembersQuery({
    page: 1,
    search: "",
    queryTag: "",
  });

  const { data: me } = useGetMeQuery();

  const [isInRange, setIsInRange] = useState<boolean | null>(null);

  useEffect(() => {
    const totalDocs = data?.data?.meta?.totalDocs;
    const memberSize = me?.data?.society?.memberSize;

    if (totalDocs && memberSize) {
      const checkRange = () => {
        switch (memberSize) {
          case "1-250":
            return totalDocs >= 1 && totalDocs <= 250;
          case "251-500":
            return totalDocs >= 251 && totalDocs <= 500;
          case "501-1000":
            return totalDocs >= 501 && totalDocs <= 1000;
          case "above 1000":
            return totalDocs > 1000;
          default:
            return false;
        }
      };

      setIsInRange(checkRange());
    } else {
      setIsInRange(null);
    }
  }, [data, me]);

  return (
    <AppLayout title="Create New Member">
      <Back />
      {isInRange === true ? (
        <div className="p-[20px] max-w-[600px] mx-auto bg-white rounded-md">
          <p className="text-red-600 font-bold">
            You've exceeded the member count. Please upgrade your subscription
            to be allowed to add a new member.
          </p>
        </div>
      ) : (
        <AddMemberForm />
      )}
    </AppLayout>
  );
};

export default AddMember;
