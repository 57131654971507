import { ChangeEvent, useState } from "react";
import { formatNumberWithCommas } from "../../../../utils/formatNumberWithCommas";
import ImageUpload from "../../CompleteRegistration/ImageUpload";
import { useMakeLoanRepayment } from "../../../../hooks/api/Loans/useMakeLoanRepayment";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetAllLoansQuery } from "../../../../hooks/api/Loans/useGetAllLoansQuery";
import { Card, Divider, Empty, message } from "antd";
import KoboConverter from "../../../../common/KoboConverter";
import ImagePreviewer from "../../../../common/ImagePreviewer";
import { getToken } from "../../../../utils/getToken";
import axios from "axios";
import NairaSign from "../../../../common/NairaSign";
import {
  useGetMemberQuery,
  useGetMembersQuery,
} from "../../../../hooks/api/Members/useGetMembersQuery";
import FinanceCard from "../../Overview/FinanceCard";

const RepaymentForm = () => {
  const { id } = useParams();
  const [, setFile] = useState<File | null>(null);

  const { data: loans, isLoading: isLoadingLoans } = useGetAllLoansQuery({
    term: "",
    status: "active",
    member: id ? id : "",
  });

  const { data, isFetching, isError } = useGetMemberQuery({ id });

  const [repayment, setRepayment] = useState({
    tag: "repayment",
    amount: "",
    paymentMethod: "",
    bank: "",
    transactionDate: "",
    entryReceipt: "",
    accountNumber: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "transactionDate") {
      const utcDate = new Date(value).toISOString();
      setRepayment((prev) => ({ ...prev, [name]: utcDate }));
    } else {
      const numericValue = value.replace(/,/g, "");
      setRepayment((prev) => ({ ...prev, [name]: numericValue }));
    }
  };

  const handleSuccess = (data: any) => {
    setRepayment({ ...repayment, entryReceipt: data?.data });
  };

  const { mutateAsync, isLoading } = useMakeLoanRepayment(
    `${loans?.data?.docs[0]?.id}`,
    true
  );

  const handleSubmit = () => {
    mutateAsync(repayment, {
      onSuccess: () => {
        setRepayment({
          tag: "repayment",
          amount: "",
          paymentMethod: "",
          bank: "",
          transactionDate: "",
          entryReceipt: "",
          accountNumber: "",
        });
      },
    });
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    const token = getToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COTRACKR_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setRepayment((prev) => ({ ...prev, entryReceipt: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const isFormIncomplete = () => {
    const requiredFields = [
      "tag",
      "amount",
      "paymentMethod",
      "transactionDate",
    ];

    return requiredFields.some(
      (field) => !repayment[field as keyof typeof repayment]
    );
  };

  return (
    <div>
      {loans?.data?.docs && loans?.data?.docs.length === 0 ? (
        <Empty
          description="This user has no active loan"
          className="py-[40px]"
        />
      ) : (
        <div className="mt-5">
          <p></p>
          <Card className="bg-[#5FBEAA33]">
            <div>
              <b>Total Repayment:</b>
              <KoboConverter
                amount={loans?.data?.docs[0]?.repaymentSchedule?.totalRepayment}
              />
            </div>
            <div>
              <b>Loan Balance:</b>
              <KoboConverter amount={data?.data?.loanBalance} />
            </div>
            {/* {loans?.data?.docs[0] &&
            loans?.data?.docs[0].repaymentSchedule.schedule > 0 ? (
              <div>
                {loans?.data?.docs[0].repaymentSchedule.schedule.map(
                  (item: any, index: number) => (
                    <div
                      key={item.id}
                      className="mb-4 p-4 border rounded shadow-sm"
                    >
                      <strong className="text-green">Month {index + 1}</strong>
                      <div>
                        <strong>Amount:</strong>{" "}
                        <span className="text-gray-500">
                          <KoboConverter amount={item.amount} />
                        </span>
                      </div>
                      <div>
                        <strong>Payment Date:</strong>{" "}
                        <span className="text-gray-500">
                          {new Date(item.paymentDate).toLocaleDateString()}
                        </span>
                      </div>
                      <div>
                        <strong>Created At:</strong>{" "}
                        <span className="text-gray-500">
                          {new Date(item.createdAt).toLocaleDateString()}{" "}
                          {new Date(item.createdAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="text-center my-[80px] text-body-text">
                <Empty description="No Repayment Data Available" />
              </div>
            )} */}
          </Card>
          <div className="">
            <div className="flex space-1 gap-2">
              <div className="w-6/12">
                <label
                  className="text-body-text mt-4 mb-1 block"
                  htmlFor="bank"
                >
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mb-2"
                  placeholder="Enter amount"
                  value={formatNumberWithCommas(`${repayment.amount}`)}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="w-6/12">
                <label
                  className="text-body-text mt-4 block"
                  htmlFor="paymentMethod"
                >
                  Payment Method
                </label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  className="p-3 rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  onChange={handleInputChange}
                  value={repayment.paymentMethod}
                >
                  <option value="" disabled>
                    --Select Option--
                  </option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
            </div>
            {repayment?.paymentMethod === "Bank Transfer" && (
              <>
                <div className="">
                  <label
                    className="text-body-text mt-4 mb-1 block"
                    htmlFor="bank"
                  >
                    Upload Receipt
                  </label>
                  {/* <ImageUpload onSuccess={handleSuccess} /> */}
                  <ImagePreviewer
                    text="Upload Receipt"
                    modalTitle="Reciept Preview"
                    prop={repayment?.entryReceipt}
                    handleFileChange={handleFileChange}
                  />
                </div>
                <div className="flex gap-2 mb-[20px]">
                  <div className="w-6/12">
                    <label
                      className="text-body-text mt-4 block"
                      htmlFor="accountNumber"
                    >
                      Account Number
                    </label>
                    <input
                      id="accountNumber"
                      name="accountNumber"
                      type="text"
                      className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                      placeholder="Enter account number"
                      value={repayment.accountNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-6/12">
                    <label className="text-body-text mt-4 block" htmlFor="bank">
                      Bank
                    </label>
                    <input
                      id="bank"
                      name="bank"
                      type="text"
                      className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                      placeholder="Enter bank name"
                      value={repayment.bank}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="">
              <label
                className="text-body-text mt-4 block"
                htmlFor="transactionDate"
              >
                Transaction Date
              </label>
              <input
                id="transactionDate"
                name="transactionDate"
                type="date"
                className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                onChange={handleInputChange}
                value={repayment.transactionDate.split("T")[0]}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>

            <div className="mt-[20px] flex justify-end">
              <button
                onClick={handleSubmit || isFormIncomplete()}
                disabled={isLoading}
                className={` text-white px-[35px] py-[10px] rounded-[100px] ${
                  isFormIncomplete() ? "bg-green/50" : "bg-green"
                }`}
              >
                {isLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "#fff" }}
                    spin
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
          <Divider />
          <FinanceCard title="Repayment History">
            {loans?.data?.docs[0]?.paymentLogs &&
            loans?.data?.docs[0]?.paymentLogs.length > 0 ? (
              loans?.data?.docs[0]?.paymentLogs.map(
                (item: any, index: number) => (
                  <div
                    key={item.id}
                    className="mb-4 p-4 border rounded shadow-sm"
                  >
                    <strong className="text-green">Month {index + 1}</strong>
                    <div>
                      <strong>Amount:</strong>{" "}
                      <span className="text-gray-500">
                        <KoboConverter amount={item.amount} />
                      </span>
                    </div>
                    <div>
                      <strong>Payment Date:</strong>{" "}
                      <span className="text-gray-500">
                        {new Date(item.paymentDate).toLocaleDateString()}
                      </span>
                    </div>
                    <div>
                      <strong>Created At:</strong>{" "}
                      <span className="text-gray-500">
                        {new Date(item.createdAt).toLocaleDateString()}{" "}
                        {new Date(item.createdAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </span>
                    </div>
                    {/* <p>
                <strong>Updated At:</strong>{" "}
                {new Date(item.updatedAt).toLocaleDateString()}{" "}
                {new Date(item.updatedAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </p> */}
                  </div>
                )
              )
            ) : (
              <div className="text-center my-[80px] text-body-text">
                <Empty description="No Repayment Data Available" />
              </div>
            )}
          </FinanceCard>
          <FinanceCard title="Breakdown">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {loans?.data?.docs[0]?.repaymentSchedule?.schedule &&
              loans?.data?.docs[0]?.repaymentSchedule?.schedule.length > 0 ? (
                loans?.data?.docs[0]?.repaymentSchedule?.schedule.map(
                  (
                    { month, monthlyPrincipal, interest, total }: any,
                    index: number
                  ) => (
                    <div key={index} className="mb-6">
                      <p className="font-bold text-green">Month {month}</p>
                      <p>
                        Principal: <KoboConverter amount={monthlyPrincipal} />
                      </p>
                      <p className="text-gray-500">
                        Interest:
                        <KoboConverter amount={interest} />
                      </p>
                      <p className="text-gray-500">
                        Total:
                        <KoboConverter amount={total} />
                      </p>
                    </div>
                  )
                )
              ) : (
                <div className="text-center my-[80px] text-body-text">
                  <Empty description="No Repayment Data Available" />
                </div>
              )}
            </div>
          </FinanceCard>
        </div>
      )}
    </div>
  );
};

export default RepaymentForm;
