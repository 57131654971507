import { useMutation } from "react-query";
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

interface Props {
  nextOfKinFirstName: string;
  nextOfKinLastName: string;
  nextOfKinAddress: string;
}

export const useUpdateMemberProfile = (id: string) => {
  const token = getToken();

  return useMutation(
    (payload: any) =>
      axiosInstance
        .patch(`${apiConfig.MEMBERS}/${id}/update-profile`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(["useGetMemberQuery"], {
          refetchActive: true,
        });

        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
