import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NewBarChart = ({ data = [] }: { data?: any[] }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <p>No data available</p>;
  }

  const labels = data.map((d) => `${d.month}/${d.year}`);
  const datasets = [
    {
      label: "Total Saving Balance",
      data: data.map((d) => (d.totalSavingBalance || 0) / 100),
      backgroundColor: "rgba(75, 192, 192, 0.6)",
    },
    {
      label: "Total Special Saving Balance",
      data: data.map((d) => (d.totalSpecialSavingBalance || 0) / 100),
      backgroundColor: "rgba(255, 99, 132, 0.6)",
    },
    {
      label: "Total Monthly Balance",
      data: data.map((d) => (d.totalMonthlyBalance || 0) / 100),
      backgroundColor: "rgba(54, 162, 235, 0.6)",
    },
    {
      label: "Total Material Building Balance",
      data: data.map((d) => (d.totalMaterialBuildingBalance || 0) / 100),
      backgroundColor: "rgba(255, 206, 86, 0.6)",
    },
    {
      label: "Total Other Savings Balance",
      data: data.map((d) => (d.totalOtherSavingsBalance || 0) / 100),
      backgroundColor: "rgba(153, 102, 255, 0.6)",
    },
    {
      label: "Total Shares Balance",
      data: data.map((d) => (d.totalSharesBalance || 0) / 100),
      backgroundColor: "rgba(255, 159, 64, 0.6)",
    },
    {
      label: "Total Loan Balance",
      data: data.map((d) => (d.totalLoanBalance || 0) / 100),
      backgroundColor: "rgba(50, 205, 50, 0.6)",
    },
  ];

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "bottom" as const },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default NewBarChart;
