import React from "react";
import { QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// PAGES
import Home from "./pages/HomePage";
import Login from "./pages/Auth/Login";
import Overview from "./pages/Dashboard/Overview";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register from "./pages/Auth/Register";
import Members from "./pages/Dashboard/Members";
import { queryClient } from "./services/queryClient";
import MemberDetails from "./pages/Dashboard/Members/MemberDetails";
import AppLayout from "./pages/Dashboard/AppLayout";
import MemberActivities from "./pages/Dashboard/Members/MemberActivities";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import LoanManagement from "./pages/Dashboard/LoanManagement";
// import Account from "./pages/Dashboard/Account";
import LoanDetails from "./pages/Dashboard/LoanManagement/LoanDetails";
import CreateLoanApplication from "./pages/Dashboard/LoanManagement/CreateLoanApplication";
import AddMember from "./pages/Dashboard/Members/AddMember";
import Deposits from "./pages/Dashboard/Deposits";
import Settings from "./pages/Dashboard/Settings";
import AccountDetails from "./pages/Dashboard/Account/AccountDetails";
import MemberDeposit from "./pages/Dashboard/Members/MemberDeposit";
import AccountActions from "./pages/Dashboard/Account/AccountActions";
import AddDeposit from "./pages/Dashboard/Deposits/AddDeposit";
import LoanRepayment from "./pages/Dashboard/LoanManagement/LoanRepayment";
import CreateTransaction from "./pages/Dashboard/BookKeeping/CreateTransaction";
import BookKeeping from "./pages/Dashboard/BookKeeping";
import SingleSepositDetails from "./pages/Dashboard/Deposits/SingleSepositDetails";
import SharedAndDividends from "./pages/Dashboard/SharesAndDividends";
import MemberWithdrawal from "./pages/Dashboard/Members/MemberWithdrawal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/overview",
    element: <Overview />,
  },
  {
    path: "/member-management",
    element: <Members />,
  },
  {
    path: "/member-management/create",
    element: <AddMember />,
  },
  { path: "/member-management/deposit/:id", element: <MemberDeposit /> },
  {
    path: "/member-management/:id",
    element: <MemberDetails />,
  },
  // {
  //   path: "/member-management/withdrawal/:id",
  //   element: <MemberWithdrawal />,
  // },
  {
    path: "/member-management/activities/:id",
    element: <MemberActivities />,
  },
  // {
  //   path: "/accounts",
  //   element: <Account />,
  // },
  // {
  //   path: "/accounts/:accountId/:memberId",
  //   element: <AccountDetails />,
  // },
  // {
  //   path: "/accounts/add",
  //   element: <AccountActions />,
  // },
  {
    path: "/loan-management",
    element: <LoanManagement />,
  },
  {
    path: "/loan-management/create",
    element: <CreateLoanApplication />,
  },
  {
    path: "/loan-management/:id",
    element: <LoanDetails />,
  },
  {
    path: "/loan-management/:id/repayment",
    element: <LoanRepayment />,
  },
  {
    path: "/deposit-savings",
    element: <Deposits />,
  },
  {
    path: "/deposit-savings/:accountId/:memberId",
    element: <SingleSepositDetails />,
  },
  {
    path: "/deposit-savings/add",
    element: <AddDeposit />,
  },
  {
    path: "/shares-dividend",
    element: <SharedAndDividends />,
  },
  {
    path: "/book-keeping",
    element: <BookKeeping />,
  },
  {
    path: "/book-keeping/create",
    element: <CreateTransaction />,
  },
  // {
  //   path: "/reports-analytics",
  //   element: (
  //     <AppLayout title="Reports">
  //       <p>REPORTS</p>
  //     </AppLayout>
  //   ),
  // },
  {
    path: "/settings",
    element: <Settings />,
  },
  { path: "*", element: <p>ERROR</p> },
]);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
