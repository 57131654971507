import React from "react";
import MemberDeposits from "./MemberActions/MemberDeposits";
import AppLayout from "../AppLayout";
import Card from "../../../common/Card";
import { useParams } from "react-router-dom";
import { useGetMemberQuery } from "../../../hooks/api/Members/useGetMembersQuery";

const MemberDeposit = () => {
  const { id } = useParams();
  const { data: memberDetails } = useGetMemberQuery({ id });

  return (
    <AppLayout
      title="Member Deposit"
      crumb={
        memberDetails?.data?.firstName + " " + memberDetails?.data?.lastName
      }
      // crumb="Member Deposit"
    >
      <Card>
        <MemberDeposits />
      </Card>
    </AppLayout>
  );
};

export default MemberDeposit;
