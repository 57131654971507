import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import Input from "../../../common/Input";
import { ChangeEvent, useEffect, useState } from "react";
import { formatNumberWithCommas } from "../../../utils/formatNumberWithCommas";
import ImagePreviewer from "../../../common/ImagePreviewer";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useWithrawSpecialSavings } from "../../../hooks/api/Entries/useWithrawSpecialSavings";

const MemberWithdrawal = () => {
  const { id } = useParams();

  const [, setFile] = useState<File | null>(null);

  const [payload, setPayload] = useState({
    tag: "withdrawal",
    amount: "",
    paymentMethod: "",
    entryReceipt: "",
    bank: "",
    accountNumber: "",
    transactionDate: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date(e.target.value).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    const token = getToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COTRACKR_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, entryReceipt: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const {
    mutateAsync,
    isLoading: isSubmitting,
    isSuccess,
  } = useWithrawSpecialSavings({
    memberId: id,
    remain: true,
  });

  useEffect(() => {
    if (isSuccess) {
      setPayload({
        tag: "withdrawal",
        amount: "",
        paymentMethod: "",
        entryReceipt: "",
        bank: "",
        accountNumber: "",
        transactionDate: "",
      });
    }
  }, [isSuccess]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutateAsync(payload, {
      onSuccess: () => {
        setPayload({
          tag: "withdrawal",
          amount: "",
          paymentMethod: "",
          entryReceipt: "",
          bank: "",
          accountNumber: "",
          transactionDate: "",
        });
      },
    });
  };

  const isFormIncomplete = () => {
    const requiredFields = [
      "amount",
      "paymentMethod",
      "tag",
      "transactionDate",
    ];

    return requiredFields.some(
      (field) => !payload[field as keyof typeof payload]
    );
  };

  return (
    <div className="">
      {/* <h3 className="text-xl mb-3">Complete the form to process withdrawal</h3> */}

      <div className="mt-3 flex gap-2 items-center">
        <div className="w-6/12">
          <label htmlFor="amount" className="text-[#14141480]">
            Amount
          </label>
          <input
            name="amount"
            type="text"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
            placeholder="Amount"
            value={formatNumberWithCommas(payload.amount)}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-6/12">
          <span className="text-body-text block">Payment Method:</span>
          <select
            className="p-3 rounded-md border-[1px] border-[#00000033] w-full mt-1"
            name="tag"
            value={payload?.paymentMethod}
            onChange={(e) => {
              setPayload({ ...payload, paymentMethod: e?.target?.value });
            }}
          >
            <option value="" disabled>
              --Select Option--
            </option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash">Cash</option>
          </select>
        </div>
      </div>
      {payload?.paymentMethod === "Bank Transfer" && (
        <>
          <div className="mb-[20px] mt-3">
            <label
              className="text-[#14141480] block mb-1"
              htmlFor="entryReceipt"
            >
              Receipt
            </label>
            <div>
              <ImagePreviewer
                text="Upload Receipt"
                modalTitle="Reciept Preview"
                prop={payload?.entryReceipt}
                handleFileChange={handleFileChange}
              />
            </div>
          </div>
          <div className="flex gap-2 mb-[20px]">
            <div className="w-6/12">
              <label className="text-[#14141480] mt-2" htmlFor="bank">
                Bank
              </label>
              <input
                name="bank"
                type="text"
                className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                placeholder=""
                value={payload.bank}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            </div>
            <div className="w-6/12">
              <label className="text-[#14141480] mt-2" htmlFor="accountNumber">
                Account Number
              </label>
              <input
                name="accountNumber"
                type="text"
                className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                placeholder=""
                value={payload.accountNumber}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </>
      )}
      <label className="text-[#14141480] mt-3 block" htmlFor="transactionDate">
        Transaction Date
      </label>
      <input
        name="transactionDate"
        type="date"
        className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 mb-[20px]"
        placeholder="Select Date"
        disabled={isSubmitting}
        max={new Date().toISOString().split("T")[0]}
        value={payload.transactionDate.split("T")[0]}
        onChange={handleDateChange}
      />
      <div className="mt-[20px] flex justify-end">
        <button
          type="submit"
          disabled={isSubmitting || isFormIncomplete()}
          onClick={(e) => handleSubmit(e)}
          className={`text-white px-[35px] py-[10px] rounded-[100px] bg-green ${
            isFormIncomplete() ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isSubmitting ? (
            <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default MemberWithdrawal;
