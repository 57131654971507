import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL =
  window.location.hostname === "cotrackr.com"
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_COTRACKR_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const stringifyToken = localStorage.getItem("COTRACKR_ADMIN_TOKEN");
      const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

      if (tokenParsed && typeof tokenParsed === "string" && config.headers) {
        config.headers.authorization = `Bearer ${tokenParsed}`;
      }
    } catch (error) {
      message.error("Failed to parse authentication token.");
    }
    return config;
  },
  async (error) => {
    message.error("Request error: " + error.message);
    console.log("==> REFRESHHH <==", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      message.error("Please login to gain access");
      const navigate = useNavigate();
      navigate("/");
    }
    return Promise.reject(error);
  }
);
