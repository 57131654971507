import { Card, Collapse } from "antd";
import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
// import KoboConverter from "../../../common/KoboConverter";

type Props = { title: string; children: React.ReactNode };

const { Panel } = Collapse;
const FinanceCard = ({ title, children }: Props) => {
  return (
    <Card
      //   key={index}
      title={title}
      bordered={false}
      className="shadow-md mb-3"
    >
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="View Details"
          key="1"
          className="site-collapse-custom-panel"
        >
          {children}
          {/* <ul className="mb-3">
            <li className="font-bold">
              Month/Year:
              {item.month}/{item.year}
            </li>
            <li>
              Total Special Saving Balance:
              <span className="text-gray-600">
                <KoboConverter amount={item.totalSpecialSavingBalance} />
              </span>
            </li>
            <li>
              Total Monthly Balance:{" "}
              <span className="text-gray-600">
                <KoboConverter amount={item.totalMonthlyBalance} />
              </span>
            </li>
            <li>
              Total Material Building Balance:
              <span className="text-gray-600">
                <KoboConverter amount={item.totalMaterialBuildingBalance} />
              </span>
            </li>
            <li>
              Total Other Savings Balance:{" "}
              <span className="text-gray-600">
                <KoboConverter amount={item.totalOtherSavingsBalance} />
              </span>
            </li>
            <li>
              Total Shares Balance:{" "}
              <span className="text-gray-600">
                <KoboConverter amount={item.totalSharesBalance} />
              </span>
            </li>
            <li>
              Total Loan Balance:
              <span className="text-gray-600">
                <KoboConverter amount={item.totalLoanBalance} />
              </span>
            </li>
          </ul> */}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default FinanceCard;
