import React, { ChangeEvent, useState } from "react";
import { formatNumberWithCommas } from "../../../../utils/formatNumberWithCommas";
import { Card, Empty, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import KoboConverter from "../../../../common/KoboConverter";
import ImageUpload from "../../CompleteRegistration/ImageUpload";
import { useWithrawSpecialSavings } from "../../../../hooks/api/Entries/useWithrawSpecialSavings";
import MemberWithdrawal from "../MemberWithdrawal";

const WithdrawalForm = ({ data, id }: { data: any; id: any }) => {
  const [payload, setPayload] = useState({
    tag: "withdrawal",
    amount: "",
    paymentMethod: "",
    entryReceipt: "",
    transactionDate: "",
    bank: "",
    accountNumber: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const handleSuccess = (data: any) => {
    setPayload({ ...payload, entryReceipt: data?.data });
  };

  const { mutateAsync: withdrawFromBalance, isLoading: isLoadingEntries } =
    useWithrawSpecialSavings({ memberId: id, remain: true });

  const handleAddDeposit = () => {
    withdrawFromBalance(payload, {
      onSuccess: (data) => {
        message.success(data?.message);
      },
      onError: (error) => {
        message.error(`Error adding deposit entry: ${error.message}`);
      },
    });
  };

  return (
    <div>
      {data?.data?.specialSavingBalance === 0 ? (
        <Empty
          description="This user has no special savings"
          className="py-[40px]"
        />
      ) : (
        <div className="mt-5">
          <Card className="bg-[#5FBEAA33]">
            <span>
              <b>Special Savings Balance:</b>{" "}
              <KoboConverter amount={data?.data?.specialSavingBalance} />
            </span>
          </Card>
          <div className="mt-3">
            <MemberWithdrawal />
            {/* <div className="flex space-1 gap-2">
              <div className="w-6/12">
                <label className="text-[#14141480] mt-2" htmlFor="amount">
                  Amount
                </label>
                <input
                  name="amount"
                  type="text"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  placeholder="Amount"
                  value={formatNumberWithCommas(payload?.amount)}
                  onChange={handleInputChange}
                  max={data?.data?.specialSavingBalance / 100}
                />
              </div>
              <div className="w-6/12">
                <ImageUpload onSuccess={handleSuccess} />
              </div>
            </div>
            <div className="flex space-1 gap-2">
              <div className="w-6/12">
                <label
                  className="text-body-text mt-4 block"
                  htmlFor="paymentMethod"
                >
                  Payment Method
                </label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  className="p-3 rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  onChange={handleChange}
                  value={payload?.paymentMethod}
                >
                <option value="" disabled>
                  --Select Option--
                </option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
              <div className="w-6/12">
                <label
                  className="text-body-text mt-4 block"
                  htmlFor="transactionDate"
                >
                  Transaction Date
                </label>
                <input
                  id="transactionDate"
                  name="transactionDate"
                  type="date"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  onChange={handleChange}
                  value={payload?.transactionDate}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            <div className="flex gap-2 mb-[20px]">
              <div className="w-6/12">
                <label
                  className="text-body-text mt-4 block"
                  htmlFor="accountNumber"
                >
                  Account Number
                </label>
                <input
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  placeholder="Enter account number"
                  value={payload?.accountNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="w-6/12">
                <label className="text-body-text mt-4 block" htmlFor="bank">
                  Bank
                </label>
                <input
                  id="bank"
                  name="bank"
                  type="text"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  placeholder="Enter bank name"
                  value={payload.bank}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-[20px] flex justify-end">
              <button
                onClick={handleAddDeposit}
                disabled={isFormIncomplete()}
                className={`p-3 font-bold text-white bg-green rounded-full ${
                  isFormIncomplete() ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoadingEntries ? (
                  <Spin
                    className="text-white"
                    size={"large"}
                    indicator={<LoadingOutlined spin />}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawalForm;
