import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

export const useGetAllLoansQuery = ({
  status,
  term,
  member,
}: {
  status?: string;
  term?: string;
  member?: string;
}) => {
  const token = getToken();

  const params: {
    status?: string;
    term?: string;
    member?: string;
  } = {};
  if (status) params.status = status;
  if (term) params.term = term;
  if (member) params.member = member;

  return useQuery({
    queryKey: ["useGetLoan", status, term, member],
    queryFn: () =>
      axiosInstance
        .get("/loans", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {
      console.log("Loans fetched successfully:", data);
    },
    onError: (error: any) => {
      message.error(error?.response?.data?.message || "Failed to fetch loans");
    },
    enabled: !!token,
  });
};
