import React, { useState } from "react";

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const togglePlan = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex justify-center mb-6 bg-water py-[10px] px-[18px] rounded-full">
        <button
          onClick={togglePlan}
          className={`py-2 px-4 rounded-full font-semibold transition-colors duration-300 ${
            isMonthly ? "bg-green text-white" : "bg-water text-black"
          }`}
        >
          Monthly
        </button>
        <button
          onClick={togglePlan}
          className={`py-2 px-4 rounded-full font-semibold transition-colors duration-300 ml-2 ${
            !isMonthly ? "bg-green text-white" : "bg-water text-black"
          }`}
        >
          Annually
        </button>
      </div>

      {isMonthly && (
        <div className="w-full mb-[40px] px-4">
          <div className="md:flex md:flex-row flex-col gap-[20px]">
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 20k</b>/Per Month
              </div>
              <h3>For Small Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">1-250 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Premium Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 30k</b>/Per Month
              </div>
              <h3>For Medium Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">251-500 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 50k</b>/Per Month
              </div>
              <h3>For Large Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">501-1000 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>Contact CoTrackr for rate</b>
              </div>
              <h3>For Extra-Large Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">Above 1000 Members</i>
            </div>
          </div>
        </div>
      )}
      {!isMonthly && (
        <div className="w-full mb-[40px] px-4">
          <div className="md:flex md:flex-row flex-col gap-[20px]">
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 200k</b>/Per Year
              </div>
              <h3>For Small Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">1-250 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Premium Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 300k</b>/Per Year
              </div>
              <h3>For Medium Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">251-500 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>NGN 500k</b>/Per Year
              </div>
              <h3>For Large Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">501-1000 Members</i>
            </div>
            <div className="md:w-3/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              {/* <p>Basic Bundle</p> */}
              <div className="flex text-[18px] mb-3">
                <b>Contact CoTrackr for rate</b>
              </div>
              <h3>For Extra-Large Scale Co-operatives or Societies.</h3>
              <i className="text-gray-400">Above 1000 Members</i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pricing;
