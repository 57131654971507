import React from "react";
import KoboConverter from "../../../common/KoboConverter";

interface BalanceItemProps {
  title: string;
  value: number | null | undefined;
}

const BalanceItem: React.FC<BalanceItemProps> = ({ title, value }) => {
  const displayValue =
    value === 0 || value == null ? "0.00" : <KoboConverter amount={value} />;

  return (
    <li>
      {title}: <span className="text-gray-600">{displayValue}</span>
    </li>
  );
};

export default BalanceItem;
