import { ChangeEvent, useState } from "react";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import { LoadingOutlined } from "@ant-design/icons";
import { formatNumberWithCommas } from "../../../utils/formatNumberWithCommas";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import { message, Spin } from "antd";
import { useMakeLoanRepayment } from "../../../hooks/api/Loans/useMakeLoanRepayment";
import ImagePreviewer from "../../../common/ImagePreviewer";

const initialState = {
  tag: "repayment",
  amount: "",
  paymentMethod: "",
  transactionDate: "",
  entryReceipt: "",
  bank: "",
  accountNumber: "",
};

const LoanRepayment = () => {
  const [payload, setPayload] = useState(initialState);
  const [file, setFile] = useState<File | null>(null);
  const token = getToken();

  const { id } = useParams();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const handleDateChange = (date: string) => {
    const formattedDate = new Date(date).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COTRACKR_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, entryReceipt: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const { mutateAsync, isLoading: isSubmittingApplication } =
    useMakeLoanRepayment(`${id}`);

  const handleSubmit = (e: any) => {
    mutateAsync(payload);
  };

  return (
    <AppLayout title="Loan Repayment">
      <section className="mx-auto w-[500px] mt-4">
        <div className="bg-white p-3 rounded-lg">
          <div className="p-[20px]">
            <Back />
            <h3 className="text-xl mb-3">Loan Repayment</h3>
            <ImagePreviewer
              text="Upload Receipt"
              modalTitle="Reciept Preview"
              prop={payload?.entryReceipt}
              handleFileChange={handleFileChange}
            />

            <p className="text-[#14141480] mt-2">Enter Amount</p>
            <input
              type="text"
              name="amount"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter amount"
              value={formatNumberWithCommas(payload.amount)}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <div className="flex gap-2 my-[20px]">
              <div className="w-6/12">
                <label
                  className="text-[#14141480] mt-2"
                  htmlFor="paymentMethod"
                >
                  Payment Method
                </label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  className="p-[12px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  onChange={handleChange}
                  value={payload.paymentMethod}
                >
                  <option value="" disabled>
                    --Select Option--
                  </option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
              <div className="w-6/12">
                <label
                  className="text-[#14141480] mt-2"
                  htmlFor="transactionDate"
                >
                  Transaction Date
                </label>
                <input
                  id="transactionDate"
                  name="transactionDate"
                  type="date"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  onChange={(e) => handleDateChange(e.target.value)}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            <div className="flex gap-2 mb-[20px]">
              <div className="w-6/12">
                <label
                  className="text-[#14141480] mt-2"
                  htmlFor="accountNumber"
                >
                  Account Number
                </label>
                <input
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  placeholder="Enter account number"
                  value={payload.accountNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="w-6/12">
                <label className="text-[#14141480] mt-2" htmlFor="bank">
                  Bank
                </label>
                <input
                  id="bank"
                  name="bank"
                  type="text"
                  className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                  placeholder="Enter bank name"
                  value={payload.bank}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={
                !payload?.accountNumber ||
                !payload?.bank ||
                !payload?.amount ||
                !payload?.paymentMethod ||
                !payload?.transactionDate ||
                !payload?.entryReceipt ||
                isSubmittingApplication
              }
              onClick={(e) => handleSubmit(e)}
              className="bg-green text-white w-full py-[10px] rounded-[100px]"
            >
              {isSubmittingApplication ? (
                <Spin
                  indicator={<LoadingOutlined />}
                  className="text-white"
                  size={"default"}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </section>
    </AppLayout>
  );
};

export default LoanRepayment;
