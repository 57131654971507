import { useState } from "react";
import AppLayout from "../AppLayout";
import { useGetDashboardOverviewQuery } from "../../../hooks/api/Dashboard/useGetDashboardOverviewQuery";
import { useGetEntriesQuery } from "../../../hooks/api/Entries/useGetEntriesQuery";
import { Card, DatePicker, Table } from "antd";
import KoboConverter from "../../../common/KoboConverter";
import Moment from "react-moment";
import FinanceCard from "./FinanceCard";
// import BarCharts from "./BarCharts";
// import RechartsGraph from "./RechartsGraph";
// import RechartsBarChart from "./RechartsBarChart";
// import StackedAreaChart from "./StackedAreaChart";
import BalanceItem from "./BalanceItem";
import Loader from "../../../common/Loader";
import dayjs from "dayjs";
import NewBarChart from "./NewBarChart";

const Overview = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { data, isLoading } = useGetDashboardOverviewQuery({
    startDate: startDate,
    endDate: endDate,
  });

  const { data: entries, isLoading: isGettingEntries } = useGetEntriesQuery({});

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  const entriesColumns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => <>{getTagLabel(tag)}</>,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => (
        <>
          <KoboConverter amount={amount} />
        </>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format="hh:mm a">{date}</Moment>
        </div>
      ),
    },
  ];

  const mockdata = Array.from({ length: 12 }, (_, i) => {
    const month = i + 1;
    const year = month < 12 ? 2025 : 2024;

    const generateRandomValue = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    return {
      month,
      year,
      monthYear: `${year}-${month}`,
      totalSavingBalance: generateRandomValue(30000000, 70000000),
      totalSpecialSavingBalance: generateRandomValue(50000000, 70000000),
      totalMonthlyBalance: generateRandomValue(10000000, 70000000),
      totalMaterialBuildingBalance: generateRandomValue(83990, 70000000),
      totalOtherSavingsBalance: generateRandomValue(30500, 70000000),
      totalSharesBalance: generateRandomValue(40000, 70000000),
      totalLoanBalance: generateRandomValue(460000, 70000000),
    };
  });

  // const formattedData = mockdata.map((item: any) => ({
  //   ...item,
  //   monthYear: `${item.month}/${item.year}`,
  // }));

  const { RangePicker } = DatePicker;

  return (
    <AppLayout title="Overview">
      <div>
        <div className="flex justify-between gap-[10px]">
          <div className="w-full md:w-4/12">
            <div className="card">
              <div className="flex justify-between mb-2">
                <h3 className="font-semibold">Deposit</h3>
                <span className="text-sm text-gray-500">Last 30 days</span>
              </div>
              <div className="text-2xl font-bold">
                <KoboConverter
                  amount={
                    data?.data?.totalDeposits?.monthlyBalance
                      ? data?.data?.totalDeposits?.monthlyBalance
                      : 0
                  }
                />
              </div>
              <p className="text-gray-500">vs. NGN 0 last period</p>
            </div>
          </div>

          <div className="w-full md:w-4/12">
            <div className="card">
              <div className="flex justify-between mb-2">
                <h3 className="font-semibold">Loans</h3>
                <span className="text-sm text-gray-500">Last 30 days</span>
              </div>
              <div className="text-2xl font-bold">
                <KoboConverter
                  amount={data?.data?.totalLoans ? data?.data?.totalLoans : 0}
                />
              </div>
              <p className="text-gray-500">vs. NGN 0 last period</p>
            </div>
          </div>

          <div className="w-full md:w-4/12">
            <div className="card">
              <div className="flex justify-between mb-2">
                <h3 className="font-semibold">Shares</h3>
                <span className="text-sm text-gray-500">Last 30 days</span>
              </div>
              <div className="text-2xl font-bold">
                <KoboConverter
                  amount={
                    data?.data?.totalDeposits?.sharesBalance
                      ? data?.data?.totalDeposits?.sharesBalance
                      : 0
                  }
                />
              </div>
              <p className="text-gray-500">vs. NGN 0 last period</p>
            </div>
          </div>
        </div>
        <div>
          {/* <BarCharts /> */}
          {/* <RechartsGraph /> */}
          {/* <RechartsBarChart data={data?.data?.monthlyTrend} /> */}
          {isLoading ? (
            <Card title="CashFlow" className="mt-[25px]">
              <div className="flex mx-auto">
                <Loader />
              </div>
            </Card>
          ) : (
            <>
              <Card title="Cash Flow" className="mt-[25px]">
                <div className="flex justify-end">
                  <div className="w-[300px]">
                    <RangePicker
                      showTime
                      className={
                        "p-3 mb-3 text-[16px] bg-white w-full border-[1px] duration-300 border-[#d3d3d3] focus:border-orange rounded-md"
                      }
                      value={
                        startDate && endDate
                          ? [dayjs(startDate), dayjs(endDate)]
                          : null
                      }
                      onCalendarChange={(dates, dateStrings) => {
                        if (dates && dates[0] && dates[1]) {
                          const startDate = dates[0].format("YYYY-MM-DD");
                          const endDate = dates[1].format("YYYY-MM-DD");

                          setStartDate(startDate);
                          setEndDate(endDate);
                        }
                      }}
                      onChange={(dates) => {
                        if (!dates || !dates[0] || !dates[1]) {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                    />
                  </div>
                </div>

                {/* {JSON.stringify(data?.data?.monthlyTrend)} */}
                <NewBarChart data={data?.data?.monthlyTrend} />
              </Card>
            </>
          )}
        </div>
        <div className="flex mt-[25px] gap-4">
          <div className="w-7/12">
            <div className="">
              <h3 className="font-bold text-xl mb-[5px]">Activities</h3>
              <p className="text-gray-600">See all recent activities</p>
            </div>
            <Table
              rowKey="id"
              columns={entriesColumns}
              loading={isGettingEntries}
              className="mt-4"
              dataSource={entries?.data?.docs.slice(0, 9)}
              scroll={{ y: "50vh" }}
              pagination={false}
            />
          </div>
          <div className="w-5/12">
            <h2 className="font-bold text-xl mb-[5px]">Finance Data</h2>
            <div className="gap-4 h-[60vh] overflow-y-scroll">
              <FinanceCard title="Total Deposits">
                <BalanceItem
                  title="Total Saving Balance"
                  value={data?.data.totalDeposits?.savingBalance}
                />
                <BalanceItem
                  title="Special Savings Balance"
                  value={data?.data.totalDeposits?.specialSavingBalance}
                />
                <BalanceItem
                  title="Monthly Balance"
                  value={data?.data.totalDeposits?.monthlyBalance}
                />
                <BalanceItem
                  title="Special Savings Balance"
                  value={data?.data.totalDeposits?.specialSavingBalance}
                />
                <BalanceItem
                  title="Material Building Balance"
                  value={data?.data.totalDeposits?.materialBuildingBalance}
                />
                <BalanceItem
                  title="Other Savings Balance"
                  value={data?.data.totalDeposits?.otherSavingsBalance}
                />
                <BalanceItem
                  title="Shares Balance"
                  value={data?.data.totalDeposits?.sharesBalance}
                />
              </FinanceCard>
              <FinanceCard title="Yearly Deposits">
                <BalanceItem
                  title="Total Saving Balance"
                  value={data?.data.yearlyDeposits?.savingBalance}
                />
                <BalanceItem
                  title="Special Savings Balance"
                  value={data?.data.yearlyDeposits?.specialSavingBalance}
                />
                <BalanceItem
                  title="Monthly Balance"
                  value={data?.data.yearlyDeposits?.monthlyBalance}
                />
                <BalanceItem
                  title="Material Building Balance"
                  value={data?.data.yearlyDeposits?.materialBuildingBalance}
                />
                <BalanceItem
                  title="Other Savings Balance"
                  value={data?.data.yearlyDeposits?.otherSavingsBalance}
                />
              </FinanceCard>
              <FinanceCard title="Monthly Deposits">
                <BalanceItem
                  title="Total Saving Balance"
                  value={data?.data.monthlyDeposits?.savingBalance}
                />
                <BalanceItem
                  title="Special Savings Balance"
                  value={data?.data.monthlyDeposits?.specialSavingBalance}
                />
                <BalanceItem
                  title="Monthly Balance"
                  value={data?.data.monthlyDeposits?.monthlyBalance}
                />
                <BalanceItem
                  title="Monthly Balance"
                  value={data?.data.monthlyDeposits?.monthlyBalance}
                />
                <BalanceItem
                  title="Material Building Balance"
                  value={data?.data.monthlyDeposits?.materialBuildingBalance}
                />
                <BalanceItem
                  title="Other Savings Balance"
                  value={data?.data.monthlyDeposits?.otherSavingsBalance}
                />
                <BalanceItem
                  title="Shares Balance"
                  value={data?.data.monthlyDeposits?.sharesBalance}
                />
              </FinanceCard>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Overview;

// const Indicators = () => {
//   return (
//     <div className="flex bg-[#E8E8E833] rounded-[10px] px-[20px] py-[11px] text-[14px] gap-[16px]">
//       <span className="flex gap-[5px] items-center">
//         <span className="h-[10px] w-[10px] rounded-full bg-green"></span>
//         <span className="font-bold text-dark">Deposit</span>
//       </span>
//       <span className="flex gap-[5px] items-center">
//         <span className="h-[10px] w-[10px] rounded-full bg-greenish"></span>
//         <span className="font-bold text-dark">Withdrawal</span>
//       </span>
//       <span className="flex gap-[5px] items-center">
//         <span className="h-[10px] w-[10px] rounded-full bg-[#CCF6F0]"></span>
//         <span className="font-bold text-dark">Loan</span>
//       </span>
//     </div>
//   );
// };
