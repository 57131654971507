import React, { useState } from "react";
import { useMutation } from "react-query";
import { uploadImage } from "./api";

interface ImageUploadProps {
  onSuccess: (data: any) => void;
  noTitle?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onSuccess, noTitle }) => {
  const [preview, setPreview] = useState<string | null>(null);

  const mutation = useMutation(uploadImage, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: () => {
      alert("Error uploading image");
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      mutation.mutate(file);
    }
  };

  return (
    <div className="">
      <div className="mb-4">
        {!noTitle && (
          <label className="block text-sm font-medium text-gray-700">
            Upload Image
          </label>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100"
        />
      </div>

      {preview && (
        <div className="mb-4">
          <img
            src={preview}
            alt="Preview"
            className="w-full h-64 object-cover rounded-md"
          />
        </div>
      )}

      {mutation.isLoading && (
        <div className="text-center text-gray-600">Uploading...</div>
      )}
    </div>
  );
};

export default ImageUpload;
