import { Divider } from "antd";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Input from "../../../common/Input";
import ImageUpload from "../Members/MemberActions/components/ImageUpload";
import { useAddDepositEntry } from "../../../hooks/api/Entries/useAddDepositEntry";

export interface Split {
  tag: string;
  amount: number;
  paymentMethod: string;
  bank: string;
  transactionDate: string;
  entryReceipt: string;
  accountNumber: string;
}

export interface Payload {
  splits: Split[];
}

interface ChangeEvent {
  target: {
    name: string;
    value: string;
  };
}

const DepositForm = ({ selectedCustomer }: any) => {
  const { id } = useParams();
  const [payload, setPayload] = useState<Payload>({
    splits: [
      {
        tag: "savings",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
      {
        tag: "special_savings",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
      {
        tag: "monthly_dues",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
      {
        tag: "development",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
      {
        tag: "other_savings",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
      {
        tag: "shares",
        amount: 0,
        paymentMethod: "",
        bank: "",
        transactionDate: "",
        entryReceipt: "",
        accountNumber: "",
      },
    ],
  });

  const handleDateChange = (value: string, tag: string) => {
    const date = new Date(value);
    date.setUTCHours(0, 0, 0, 0);
    const isoString = date.toISOString();

    setPayload((prevPayload: Payload) => ({
      splits: prevPayload.splits.map((split) =>
        split.tag === tag
          ? {
              ...split,
              transactionDate: isoString,
            }
          : split
      ),
    }));
  };

  const handleChange = (e: ChangeEvent, tag: string, field?: keyof Split) => {
    const { name, value } = e.target;

    setPayload((prevPayload: Payload) => ({
      splits: prevPayload.splits.map((split) =>
        split.tag === tag
          ? {
              ...split,
              [field || name]:
                (field || name) === "amount"
                  ? parseInt(value, 10) * 100
                  : value,
            }
          : split
      ),
    }));
  };

  const renderInputFields = (splitTag: string) => {
    const splitData = payload.splits.find((split) => split?.tag === splitTag);

    const handleUploadSuccess = (fileUrl: any, splitTag: string) => {
      setPayload((prevPayload) => ({
        ...prevPayload,
        splits: prevPayload.splits.map((split) =>
          split.tag === splitTag
            ? { ...split, entryReceipt: fileUrl?.data }
            : split
        ),
      }));
    };
    if (!splitData) {
      return null;
    }
    return (
      <div className="w-9/12">
        <div className="flex space-1 gap-2">
          <div className="w-6/12">
            <Input
              label="Amount"
              name="amount"
              type="number"
              value={String(splitData.amount / 100).toLocaleString()}
              placeholder="Enter amount in naira"
              onChange={(e) => handleChange(e, splitTag, "amount")}
            />
          </div>
          <div className="w-6/12">
            <ImageUpload
              splitTag={splitTag}
              onUploadSuccess={handleUploadSuccess}
              payload={payload}
            />
          </div>
        </div>
        <div className="flex space-1 gap-2">
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="paymentMethod"
            >
              Payment Method
            </label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              className="p-3 rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={(e) => handleChange(e, splitTag, "paymentMethod")}
              value={splitData.paymentMethod}
            >
              <option value="" disabled>
                --Select Option--
              </option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Cash">Cash</option>
            </select>
          </div>
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="transactionDate"
            >
              Transaction Date
            </label>
            <input
              id="transactionDate"
              name="transactionDate"
              type="date"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={(e) => handleDateChange(e.target.value, splitTag)}
              value={splitData.transactionDate.split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className="flex gap-2 mb-[20px]">
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="accountNumber"
            >
              Account Number
            </label>
            <input
              id="accountNumber"
              name="accountNumber"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter account number"
              value={splitData.accountNumber}
              onChange={(e) => handleChange(e, splitTag, "accountNumber")}
            />
          </div>
          <div className="w-6/12">
            <label className="text-body-text mt-4 block" htmlFor="bank">
              Bank
            </label>
            <input
              id="bank"
              name="bank"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter bank name"
              value={splitData.bank}
              onChange={(e) => handleChange(e, splitTag, "bank")}
            />
          </div>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  const {
    mutateAsync: addEntry,
    isLoading: isSubmitting,
    isSuccess,
  } = useAddDepositEntry(selectedCustomer?.id);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  const handleSubmit = () => {
    const filteredSplits = payload.splits.filter((split) => {
      const { amount, paymentMethod, bank, transactionDate } = split;
      return amount > 0 && paymentMethod && bank && transactionDate;
    });

    const updatedPayload = {
      ...payload,
      splits: filteredSplits,
    };

    addEntry(updatedPayload);
  };

  // const handleSubmit = () => {
  // const filteredSplits = payload.splits.filter((split) => {
  //   return (
  //     split.amount > 0 &&
  //     split.paymentMethod &&
  //     split.bank &&
  //     split.transactionDate &&
  //     split.entryReceipt &&
  //     split.accountNumber
  //   );
  // });
  // if (filteredSplits.length > 0) {
  //   const updatedPayload = { splits: filteredSplits };
  //   // addEntry(updatedPayload);
  // } else {
  //   console.error("No valid entries to submit.");
  // }
  // };

  return (
    <div>
      <h3 className="text-black">Details</h3>
      <div>
        <div className="flex mt-5">
          <div className="w-3/12">Savings</div>
          {renderInputFields("savings")}
        </div>
        <Divider />
        <div className="flex mt-5">
          <div className="w-3/12">Special Savings</div>
          {renderInputFields("special_savings")}
        </div>
        <Divider />
        <div className="flex mt-5">
          <div className="w-3/12">Development</div>
          {renderInputFields("development")}
        </div>
        <Divider />
        <div className="flex mt-5">
          <div className="w-3/12">Monthly Dues</div>
          {renderInputFields("monthly_dues")}
        </div>
        <Divider />
        <div className="flex mt-5">
          <div className="w-3/12">Other Savings</div>
          {renderInputFields("other_savings")}
        </div>
        <Divider />
        <div className="flex mt-5">
          <div className="w-3/12">Shares</div>
          {renderInputFields("shares")}
        </div>
        <Divider />
        <div className="mt-[20px] flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className={` text-white px-[35px] py-[10px] rounded-[100px] ${
              isSubmitting ? "bg-green/50" : "bg-green"
            }`}
          >
            {isSubmitting ? (
              <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositForm;
