import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import { useGetLoanQuery } from "../../../hooks/api/Loans/useGetLoanQuery";
import Loader from "../../../common/Loader";
import Card from "../../../common/Card";
import NairaSign from "../../../common/NairaSign";
import { Divider, Drawer, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import { useApproveLoanApplication } from "../../../hooks/api/Loans/useApproveLoanApplication";
import RepaymentDataDisplay from "./components/RepaymentDataDisplay";
import KoboConverter from "../../../common/KoboConverter";
import { useCheckIfLoanIsOverdue } from "../../../hooks/api/Loans/useCheckIfLoanIsOverdue";
import { useMarkLoanAsOverdue } from "../../../hooks/api/Loans/useMarkLoanAsOverdue";

const LoanDetails = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [overdueModal, setOverdueModal] = useState(false);
  const { data, isFetching, isError } = useGetLoanQuery(`${id}`);
  const { data: loanStatus } = useCheckIfLoanIsOverdue(id);
  const { mutateAsync, isLoading: isApproving } = useApproveLoanApplication();
  const { mutateAsync: mutateStatus, isLoading: isChanging } =
    useMarkLoanAsOverdue();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutateAsync(`${id}`, { onSuccess: () => setOpen(false) });
  };

  const handleOverdue = (e: any) => {
    e.preventDefault();
    mutateStatus(`${id}`, { onSuccess: () => setOverdueModal(false) });
  };

  if (isFetching) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <Loader />
          </div>
        </div>
      </AppLayout>
    );
  }

  if (isError || !data) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <p className="text-red-500">
              Failed to loan details. Please try again later.
            </p>
          </div>
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout title="Loan Details">
      <div className="flex justify-between items-center">
        <Back />
        {data?.data?.loanStatus === "pending" && (
          <button
            onClick={() => setOpen(true)}
            className="text-white rounded-full p-[10px] border-[1px] bg-green text-[13px]"
          >
            Approve Loan
          </button>
        )}
      </div>
      {loanStatus?.data?.isOverDue && (
        <div className="text-center">
          <span className="mt-[20px] bg-[#fcd34d] text-black p-2 rounded-md">
            {loanStatus?.data?.info}
          </span>
        </div>
      )}
      <Modal
        open={open}
        title="Approve Loan"
        closeIcon={false}
        className="max-w-[400px]"
        footer={null}
      >
        <p>
          Are you sure you want to approve this loan? This action is
          irreversible.
        </p>
        <div className="flex justify-end gap-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-green text-white rounded"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {isApproving ? (
              <Spin
                className="text-white"
                size={"default"}
                indicator={<LoadingOutlined spin />}
              />
            ) : (
              "Approve"
            )}
          </button>
        </div>
      </Modal>
      <Modal
        open={overdueModal}
        title="Mark Loan as Overdue"
        closeIcon={false}
        className="max-w-[400px]"
        footer={null}
      >
        <p>
          Are you sure you want to mark this loan as overdue? This action is
          irreversible.
        </p>
        <div className="flex justify-end gap-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={() => {
              setOverdueModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={(e) => {
              handleOverdue(e);
            }}
          >
            {isChanging ? (
              <Spin
                className="text-white"
                size={"default"}
                indicator={<LoadingOutlined spin />}
              />
            ) : (
              "Mark as Overdue"
            )}
          </button>
        </div>
      </Modal>
      <br />
      <div className="flex gap-[10px] mb-[10px]">
        <div className="w-4/12">
          <Card>
            <div className="mb-[12px]">
              <div className="flex justify-between">
                <div>
                  <h2 className="font-semibold text-[20px] text-dark">
                    Loan Balance
                  </h2>
                  <p className="text-body-text">View Member Loan Details</p>
                </div>
                <div>
                  Status:{" "}
                  <span
                    className={`px-2 py-1 rounded-lg capitalize ${
                      data?.data?.loanStatus === "pending"
                        ? "bg-yellow-500 text-white"
                        : data?.data?.loanStatus === "active"
                        ? "bg-red-500 text-white"
                        : data?.data?.loanStatus === "paid"
                        ? "bg-[#4caf50]  text-white"
                        : ""
                    }`}
                  >
                    {data?.data?.loanStatus}
                  </span>
                </div>
              </div>
            </div>
            <Divider />
            <div className="bg-[#5FBEAA33] rounded-md flex gap-[30px] items-center p-[10px]">
              <span className="min-w-[24px] bg-white rounded-full min-h-[24px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 m-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
                  />
                </svg>
              </span>
              <span>
                <div className="text-[#EB001B] font-bold text-[25px]">
                  <KoboConverter
                    amount={data?.data?.repaymentSchedule?.totalRepayment}
                  />
                </div>
                <p>
                  Interest Amount:
                  <KoboConverter
                    amount={data?.data?.repaymentSchedule?.totalInterest}
                  />
                </p>
                <p>
                  Loan Balance:
                  <KoboConverter amount={data?.data?.member?.loanBalance} />
                </p>
              </span>
            </div>
          </Card>
        </div>
        <div className="w-8/12 bg-white rounded-[10px] py-[24px] px-[16px]">
          <div className="mb-[6px]">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="font-semibold text-[18px] text-dark">
                  Loan Details
                </h2>
                <p className="text-body-text">View Loan Details</p>
              </div>
              <span>
                {loanStatus?.data?.isOverDue && (
                  <button
                    onClick={() => setOverdueModal(true)}
                    className="p-2 rounded-full bg-red-500 text-white mr-2"
                  >
                    Mark as overdue
                  </button>
                )}
                {data?.data?.loanStatus === "active" && (
                  <Link
                    to={`/loan-management/${id}/repayment`}
                    className="text-green rounded-full p-[10px] border-[1px] border-green text-[13px]"
                  >
                    Loan Repayment
                  </Link>
                )}
              </span>
            </div>
          </div>
          <Divider />
          <div className="grid grid-cols-4 gap-2">
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Currency />
              </div>
              <div className="w-10/12">
                <p>Loan Amount</p>
                <div className="font-bold text-gray-500">
                  <KoboConverter amount={data?.data?.amount} />
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Calendar />
              </div>
              <div className="w-10/12">
                <p>Date Applied</p>
                <div className="font-bold text-gray-500">
                  <Moment format="DD-MM-YYYY">{data?.data?.dateApplied}</Moment>
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Time />
              </div>
              <div className="w-10/12">
                <p>Time</p>
                <div className="font-bold text-gray-500">
                  <Moment format="hh:mm:a">{data?.data?.dateApplied}</Moment>
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Term />
              </div>
              <div className="w-10/12">
                <p>Term</p>
                <div className="font-bold text-gray-500">
                  <span>{data?.data?.term} months</span>
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Rate />
              </div>
              <div className="w-10/12">
                <p>Rate</p>
                <div className="font-bold text-gray-500">
                  <span>{data?.data?.interestRate} %</span>
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <EMI />
              </div>
              <div className="w-10/12">
                <p>EMI</p>
                <div className="font-bold text-gray-500">
                  <span>---</span>
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Interest />
              </div>
              <div className="w-10/12">
                <p>Interest Amount</p>
                <div className="font-bold text-gray-500">
                  <KoboConverter
                    amount={data?.data?.repaymentSchedule?.totalInterest}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <div className="w-2/12">
                <Interest />
              </div>
              <div className="w-10/12">
                <p>Est. Return Date</p>
                <div className="font-bold text-gray-500">
                  <span>
                    {data?.data?.repaymentSchedule?.schedule && (
                      <Moment format="DD-MM-YYYY">
                        {
                          data?.data?.repaymentSchedule?.schedule[
                            data?.data?.repaymentSchedule?.schedule.length - 1
                          ]?.repaymentDate
                        }
                      </Moment>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-4/12 p-3 bg-white rounded-md">
          <section className="mb-6">
            {/* <div className="flex mb-4">
              <div className="w-6/12">
                <p className="text-gray-500">Total Interest</p>
                <h3 className="font-bold text-2xl">
                  ₦
                  {data?.data?.repaymentSchedule?.totalInterest.toLocaleString() ||
                    "N/A"}
                </h3>
              </div>
              <div className="w-6/12">
                <p className="text-gray-500">Total Repayment</p>
                <h3 className="font-bold text-2xl">
                  ₦
                  {data?.data?.repaymentSchedule?.totalRepayment.toLocaleString() ||
                    "N/A"}
                </h3>
              </div>
            </div>
            <hr /> */}

            <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
              Information
            </h2>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <p className="text-gray-500">User ID:</p>
                <Link
                  to={`/member-management/${data?.data?.member?.id}`}
                  className="font-medium text-green underline"
                >
                  {data.data.user || "N/A"}
                </Link>
              </div>
              <div>
                <p className="text-gray-500">Name:</p>
                <p className="font-medium">
                  {data?.data?.member.firstName +
                    " " +
                    data?.data?.member.lastName || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Email:</p>
                <p className="font-medium">
                  {data?.data?.member.email || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Loan Status:</p>
                <p className="font-medium capitalize">
                  {data.data.loanStatus || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Term:</p>
                <p className="font-medium">{data.data.term || "N/A"}</p>
              </div>
              <div>
                <p className="text-gray-500">Interest Rate:</p>
                <p className="font-medium">
                  {data.data.interestRate + "%" || "N/A"}
                </p>
              </div>
            </div>
          </section>
          <hr />
          <section className="mb-6 mt-3">
            <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
              Guarantors
            </h2>
            <div>
              {data?.data?.guarantors.map(
                ({
                  id,
                  email,
                  firstName,
                  lastName,
                  phoneNumber,
                  savingBalance,
                }: any) => (
                  <div key={id} className="mb-[30px]">
                    <Link
                      to={`/member-management/${id}`}
                      className="text-green  underline"
                    >
                      {firstName + " " + lastName}
                    </Link>
                    <div className="flex items-center gap-2 mb-3 mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                        />
                      </svg>
                      {email}
                    </div>
                    <div className="flex items-center gap-2 mb-3 mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                      {phoneNumber}
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
        <div className="w-4/12">
          <RepaymentDataDisplay data={data?.data?.paymentLogs} />
        </div>
        <div className="w-4/12">
          <section className="mb-6 p-3 bg-white rounded-md">
            <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
              Breakdown
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {data?.data?.repaymentSchedule.schedule.map(
                ({ month, monthlyPrincipal, interest, total }: any) => (
                  <div key={month} className="mb-6">
                    <p className="font-bold text-green">Month {month}</p>
                    <p>
                      Principal: <KoboConverter amount={monthlyPrincipal} />
                    </p>
                    <p className="text-gray-500">
                      Interest:
                      <KoboConverter amount={interest} />
                    </p>
                    <p className="text-gray-500">
                      Total:
                      <KoboConverter amount={total} />
                    </p>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
      </div>
    </AppLayout>
  );
};

export default LoanDetails;

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
      />
    </svg>
  );
};
const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
      />
    </svg>
  );
};

const Currency = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
      />
    </svg>
  );
};

const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
      />
    </svg>
  );
};

const Time = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
};

const Term = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 6h.008v.008H6V6Z"
      />
    </svg>
  );
};

const Rate = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
      />
    </svg>
  );
};

const EMI = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
      />
    </svg>
  );
};

const Interest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
};
